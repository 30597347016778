import { differenceInDays, isWithinInterval } from 'date-fns';
import { Plan, SubscriptionType } from 'typings/api';
import { User as APIUser, UserPromoType } from '../typings/api/user';

class User implements APIUser {
  id?: number;
  email?: string;
  full_name?: string;
  address?: string;
  subscribed_to_newsletter?: boolean;
  first_name?: string;
  last_name?: string;
  birthday?: string;
  payment_method?: 'STRIPE' | 'BANK_ACCOUNT' | 'NONE';
  subscription_type?: SubscriptionType;
  subscription_expiration?: string;
  auto_renew?: boolean;
  plan?: Plan;
  register_time: Date;
  components: { [index: string]: number };
  had_subscription: boolean;
  tax_country_id: number;
  language?: string;
  subscription_purchased_till?: string;
  auto_renew_failed_attempts?: number;
  auto_renew_remaining_attempts?: number;
  content_languages: string[] = [];
  promo_code_active: boolean;
  promo_code?: UserPromoType;
  home_country: string;

  // server-side
  ip?: string;
  authToken?: string;
  locale?: string;

  get showProfileWarning(): boolean {
    return this.isInGracePeriod && this.auto_renew;
  }

  get needsToPay(): boolean {
    return this.isSubscriber && this.isInGracePeriod && this.auto_renew;
  }

  get isInGracePeriod(): boolean {
    try {
      const now = new Date();
      const expiration = new Date(this.subscription_expiration);
      const subscriptionTill = new Date(this.subscription_purchased_till);
      return !!(this.plan && isWithinInterval(now, { start: subscriptionTill, end: expiration }));
    } catch {
      return false;
    }
  }

  get isSubscriber(): boolean {
    if (this.subscription_expiration) {
      return new Date(this.subscription_expiration) > new Date();
    }
    return !!this.subscription_type && this.subscription_type !== SubscriptionType.None;
  }

  get isWeekOldWithoutSub(): boolean {
    return differenceInDays(new Date(), new Date(this.register_time)) <= 7 && !this.isSubscriber;
  }

  get showAdvancedChoosePlanForm(): boolean {
    return !this.had_subscription && this.components && this.components['choose_plan'] === 1;
  }

  static fromJSON(json: Partial<APIUser>): User {
    return Object.assign(new User(), json);
  }
}

export default User;
