import { RouteType } from 'enums/routeType';
import User from 'models/User';
import { locales, defaultLocale } from 'i18n';
import { IdSlug, PlayWithTheatreSlug } from 'typings/notGenerated';
import { GetServerSidePropsContext, Redirect } from 'next';
import { StaticPathParams } from 'typings/staticPaths';
import { getPrefferedDomainForCountry, slugifyString } from 'helpers';
import { isGoogleUserAgent } from './env';
import { SearchResponseItem } from 'typings/api';
import { parseQueryString } from 'api/apiHelpers';
import { ApiClient } from 'api/client';
import routeTable from 'config/routeTable';
import { Country } from 'enums/country';
import slugify from 'slugify';

/**
 * Static class exposing all portal url helpers.
 *
 * @export
 * @class Permalink
 */
export class Permalink {
  static play(play: PlayWithTheatreSlug, locale = defaultLocale): string {
    const theatreSlug = encodeURI(
      `${play.theatre.id}-${slugifyString(
        play.theatre.names?.find((n) => n.language === locale)?.value ||
          play.theatre.names?.find((n) => n.language === defaultLocale).value ||
          play.theatre.name,
      )}`,
    );

    const playSlug = encodeURI(
      `${play.id}-${slugifyString(
        play.names?.find((n) => n.language === locale)?.value ??
          play.names?.find((n) => n.language === defaultLocale)?.value ??
          play.title ??
          play.name,
      )}`,
    );

    return `/${RouteType.PlayDetail[locale].replace('[slug]', theatreSlug).replace('[playSlug]', playSlug)}`;
  }

  static playFromSearchResponse(play: SearchResponseItem, locale = defaultLocale): string {
    return `/${RouteType.PlayDetail[locale]
      .replace('[slug]', encodeURI(`${play.theatre}-${slugifyString(play.theatre_name)}`))
      .replace('[playSlug]', encodeURI(`${play.id}-${slugifyString(play.name)}`))}`;
  }

  static celebrity(celebrity: IdSlug & { name?: string }, locale = defaultLocale): string {
    const slug = `${celebrity.id ? `${celebrity.id}-` : ''}${slugifyString(
      celebrity.names?.find((n) => n.language === locale)?.value ||
        celebrity.names?.find((n) => n.language === defaultLocale)?.value ||
        celebrity.name,
    )}`;

    return `/${RouteType.Celebrity[locale].replace('[slug]', slug)}`;
  }

  static theatres(locale = defaultLocale): string {
    return `/${RouteType.Theatres[locale]}`;
  }

  static watchList(locale = defaultLocale): string {
    return `/${RouteType.WatchList[locale]}`;
  }

  static theatre(
    theatre: { id: number; title?: string; name?: string; names?: { language: string; value: string }[] },
    locale = defaultLocale,
  ): string {
    const slug = encodeURI(
      `${theatre.id ? `${theatre.id}-` : ''}${slugifyString(
        theatre.names?.find((n) => n.language === locale)?.value ||
          theatre.names?.find((n) => n.language === defaultLocale).value ||
          theatre.name,
      )}`,
    );

    return `/${RouteType.TheatreDetail[locale].replace('[slug]', slug)}`;
  }

  static preview(play: IdSlug & { title?: string; name?: string }, locale = defaultLocale): string {
    return `/${RouteType.Preview[locale].replace(
      '[slug]',
      encodeURI(`${play.id}-${slugifyString(play.title ?? play.name)}`),
    )}`;
  }

  static watch(play: IdSlug & { title?: string; name?: string }, locale = defaultLocale): string {
    return `/${RouteType.Watch[locale].replace(
      '[slug]',
      encodeURI(`${play.id}-${slugifyString(play.title ?? play.name)}`),
    )}`;
  }

  static collection(locale = defaultLocale, queryString?: string): string {
    return `/${RouteType.Collection[locale]}${queryString ? `?${queryString}` : ''}`;
  }

  static genre(section: { id: number; name: string }, locale = defaultLocale): string {
    return `/${RouteType.Genre[locale].replace('[slug]', encodeURI(`${section.id}-${slugifyString(section.name)}`))}`;
  }

  static featuredSection(section: { id: number; name: string }, locale = defaultLocale): string {
    return `/${RouteType.FeaturedSection[locale].replace(
      '[slug]',
      encodeURI(`${section.id}-${slugifyString(section.name)}`),
    )}`;
  }

  static mostPopular(locale = defaultLocale): string {
    return `/${RouteType.MostPopular[locale]}`;
  }

  static currentlyWatching(locale = defaultLocale): string {
    return `/${RouteType.CurrentlyWatching[locale]}`;
  }
  static dramoxOriginals(locale = defaultLocale): string {
    return `/${RouteType.DramoxOriginals[locale]}`;
  }

  static recentlyAdded(locale = defaultLocale): string {
    return `/${RouteType.RecentlyAdded[locale]}`;
  }

  static sameGenre(play: IdSlug & { title: string }, locale = defaultLocale): string {
    return `/${RouteType.SameGenre[locale].replace('[slug]', encodeURI(`${play.id}-${slugifyString(play.title)}`))}`;
  }

  static sameCast(play: IdSlug & { title: string }, locale = defaultLocale): string {
    return `/${RouteType.SameCast[locale].replace('[slug]', encodeURI(`${play.id}-${slugifyString(play.title)}`))}`;
  }

  static about(locale = defaultLocale): string {
    return `/${RouteType.About[locale]}`;
  }

  static forSchools(locale = defaultLocale): string {
    return `/${RouteType.ForSchools[locale]}`;
  }

  static choosePlan(): string {
    return `/${RouteType.ChoosePlan}`;
  }

  static smartTV(locale = defaultLocale): string {
    return `/${RouteType.SmartTV[locale]}`;
  }

  static contact(locale = defaultLocale): string {
    return `/${RouteType.Contact[locale]}`;
  }

  static faq(locale = defaultLocale): string {
    return `/${RouteType.FAQ[locale]}`;
  }

  static privacyPolicy(locale = defaultLocale): string {
    return `/${RouteType.PrivacyPolicy[locale]}`;
  }

  static cookie(locale = defaultLocale): string {
    return `/${RouteType.Cookies[locale]}`;
  }

  static termsAndConditions(locale = defaultLocale): string {
    return `/${RouteType.TermsAndConditions[locale]}`;
  }

  static authorship(locale = defaultLocale): string {
    return `/${RouteType.Authorship[locale]}`;
  }

  static login(locale = defaultLocale): string {
    return `/${RouteType.Login[locale]}`;
  }

  static forgottenPassword(locale = defaultLocale): string {
    return `/${RouteType.ForgottenPassword[locale]}`;
  }

  static voucher(locale = defaultLocale): string {
    return `/${RouteType.Voucher[locale]}`;
  }

  static donate(locale = defaultLocale): string {
    return `/${RouteType.Donate[locale]}`;
  }

  static registerStep1(locale = defaultLocale): string {
    return `/${RouteType.RegisterStep1[locale]}`;
  }

  static registerStep2(locale = defaultLocale): string {
    return `/${RouteType.RegisterStep2[locale]}`;
  }

  static registerConfirmEmail(locale = defaultLocale): string {
    return `/${RouteType.RegisterConfirmEmail[locale]}`;
  }

  static registerContentSettings(locale = defaultLocale, queryString?: string): string {
    return `/${RouteType.RegisterContentSettings[locale]}${queryString ? `?${queryString}` : ''}`;
  }

  static contentSettings(locale = defaultLocale): string {
    return `/${RouteType.ContentSettings[locale]}`;
  }

  static donateTheatre(
    theatre: { id: number; title?: string; name?: string; names?: { language: string; value: string }[] },
    locale = defaultLocale,
  ): string {
    const slug = encodeURI(
      `${theatre.id ? `${theatre.id}-` : ''}${slugifyString(
        theatre.names?.find((n) => n.language === locale)?.value ||
          theatre.names?.find((n) => n.language === defaultLocale).value ||
          theatre.name,
      )}`,
    );

    return `/${RouteType.DonateTheatre[locale].replace('[slug]', slug)}`;
  }

  static howToWatch(locale = defaultLocale): string {
    return `/${RouteType.HowToWatch[locale]}`;
  }

  static howToWatchWeb(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchWeb[locale]}`;
  }

  static howToWatchSmartTV(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchSmartTV[locale]}`;
  }

  static howToWatchSmartTVSamsung(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchSmartTVSamsung[locale]}`;
  }

  static howToWatchSmartTVLG(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchSmartTVLG[locale]}`;
  }

  static howToWatchMobile(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchMobile[locale]}`;
  }

  static howToWatchMobileAndroid(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchMobileAndroid[locale]}`;
  }

  static howToWatchMobileIOS(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchMobileIOS[locale]}`;
  }

  static HowToWatchStreaming(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchStreaming[locale]}`;
  }

  static HowToWatchStreamingChromecast(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchStreamingChromecast[locale]}`;
  }

  static HowToWatchStreamingAirplay(locale = defaultLocale): string {
    return `/${RouteType.HowToWatchStreamingAirplay[locale]}`;
  }

  static root(user: User, locale = defaultLocale, queryString?: string): string {
    if (user) {
      return this.collection(locale, queryString);
    }

    return `/${RouteType.Welcome}${queryString ? `?${queryString}` : ''}`;
  }

  static christmasContest(locale = defaultLocale): string {
    return `/${RouteType.ChristmasContest[locale]}`;
  }

  static inviteFriends(locale = defaultLocale): string {
    return `/${RouteType.InviteFriends[locale]}`;
  }

  static uaSupport(locale = defaultLocale): string {
    return `/${RouteType.UaSupport[locale]}`;
  }

  static easterContest(locale = defaultLocale): string {
    return `/${RouteType.EasterContest[locale]}`;
  }

  static broadcast(locale = defaultLocale): string {
    return `/${RouteType.Broadcast[locale]}`;
  }
}

/**
 * Converts current url to a localised url for a different locale.
 *
 * @export
 * @param {string} url
 * @param {string} newLocale
 * @returns {string}
 */
export function localiseUrl(url: string, newLocale: string, currentLocale = defaultLocale): string {
  if (!url) {
    return url;
  }

  const [_url, queryString] = url.split('?');
  const [, page, ...parts] = _url.split('/');

  let final: string;

  try {
    Object.values(RouteType).forEach((route) => {
      if (typeof route === 'object') {
        let [_page, ..._parts] = route[currentLocale].split('/');
        if (`/${route[currentLocale]}` === url) {
          final = `/${route[newLocale]}`;
        } else if (!final && page === _page && parts.length === _parts.length) {
          // we found a match for a route in current language, now deconstruct url for new locale
          [_page, ..._parts] = route[newLocale].split('/');
          final = `/${_page}/${_parts
            .map((_p, i) => {
              if (_p === '[slug]' || _p === '[playSlug]') {
                return parts[i];
              }

              return _p;
            })
            .join('/')}`;
        }
      }
    });
  } catch (e) {
    console.warn(url, newLocale, currentLocale);
  }

  if (!final) {
    final = url;
  }

  if (queryString) {
    final = `${final}?${queryString}`;
  }

  return final;
}

export function getLocaleFromUrl(url: string, host?: string, currentLocale?: string): string {
  const page = url.split('/')[1].split('?')[0];
  if (locales.indexOf(page) > -1) {
    return page;
  }

  const queryString = url.split('?')[1];

  let locale: string;

  if (queryString) {
    const query = parseQueryString(queryString);
    locale =
      query['lang'] ?? query['locale '] ?? (query['redirectUrl'] ? getLocaleFromUrl(query['redirectUrl'], host) : null);
  }

  if (!locale) {
    Object.values(RouteType)
      .filter((route) => typeof route === 'object')
      .forEach((route) => {
        if (!locale) {
          locales.forEach((_locale) => {
            // route matches current page
            if (route[_locale].indexOf(page) > -1) {
              if (currentLocale === _locale) {
                /*
                 * If routes for different languages are the same, take currentLocale into account (if possible)
                 */
                locale = _locale;
              } else if ((host && localeFromHost(host) === _locale) || !locale) {
                /*
                 * If routes for different languages are the same, take host into account (if possible)
                 */
                locale = _locale;
              }
            }
          });
        }
      });
  }

  return locale;
}

export function isLoginRoute(route: string): boolean {
  return !!locales.map(Permalink.login).find((p) => route.indexOf(p) !== -1);
}

export function isPerformanceRoute(route: string): boolean {
  const routeParts = route.split('/').filter((route) => !!route);
  return !!Object.values(RouteType.PlayDetail).find((playRoute) => {
    const playRouteParts = playRoute.split('/');
    return playRouteParts[0] === routeParts[0] && playRouteParts.length === routeParts.length;
  });
}

export function isHomeRoute(route: string): boolean {
  return route === '/';
}

export function isBrowseRoute(route: string): boolean {
  return route === '/browse';
}

export function createRedirectIfNeeded(
  ctx: GetServerSidePropsContext<StaticPathParams>,
  locale: string,
): { redirect: Redirect } {
  const { url } = ctx.req;
  const currentUrlLocale = getLocaleFromUrl(url, null, locale);

  // 1) get locale from the url, ie. /celebrity/ is recognised as "en"
  // 2) current locale is "cz", so translate to /osobnost/ and redirect!
  if (currentUrlLocale !== locale) {
    const localisedUrl = localiseUrl(url, locale, currentUrlLocale);

    // prevent weird 308 language redirect on local dev
    if (localisedUrl.indexOf('.json') > -1) {
      return null;
    }

    // prevent cycling on the same url
    if (url === localisedUrl) {
      return null;
    }

    return {
      redirect: {
        permanent: true,
        destination: localisedUrl,
      },
    };
  }

  return null;
}

export function localeFromHost(host: string): string {
  const country = Object.keys(routeTable).find((key) => routeTable[key] === host);
  switch (country) {
    case Country.Czechia:
      return 'cs';
    case Country.Ukraine:
      return 'uk';
    case 'default':
      return 'en';
    default:
      return country;
  }
}

export function domainFromHost(host: string): string {
  const domain = Object.keys(routeTable).find((key) => routeTable[key] === host);
  if (domain === 'default') {
    return 'tv';
  }

  return domain;
}

export function countryFromHost(host: string): string {
  const country = Object.keys(routeTable).find((key) => routeTable[key] === host);
  if (country === 'default') {
    return 'xx';
  }

  return country;
}

export function kinoboxLink(type: 'person' | 'movie', kinoboxId: number, name: string): string {
  if (type === 'person') {
    return `https://www.kinobox.cz/osobnosti/${kinoboxId}-${slugify(name, { lower: true })}`;
  }

  return `https://www.kinobox.cz/film/${kinoboxId}-${slugify(name, { lower: true })}`;
}
